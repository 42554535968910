<template>
  <div class="commodity-out-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="startDateClick">
          <span>{{ startDateComputed }}</span>
          <van-icon name="arrow-down" />
        </div>

        <!-- <div class="filter-item" @click="endDateClick">
          <span>{{ endDateComputed }}</span>
          <van-icon name="arrow-down" />
        </div> -->

        <div class="filter-item"
             @click="warehouseClick">
          <span>{{ warehouseComputed }}</span>
          <van-icon name="arrow-down" />
        </div>

        <!-- <div class="filter-item" @click="warehousingTypeClick">
          <span>{{ warehousingTypeComputed }}</span>
          <van-icon name="arrow-down" />
        </div> -->
        <div class="filter-item"
             @click="statusClick">
          <span>{{ statusComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <van-calendar v-model="showStartDate"
                    :min-date="minDate._d"
                    :formatter="startDateFormatter"
                    @confirm="startDateConfirm"
                    :show-confirm="false" />
      <van-calendar v-model="showEndDate"
                    :min-date="minDate._d"
                    :formatter="endDateFormatter"
                    @confirm="endDateConfirm"
                    :show-confirm="false" />
      <van-popup v-model="showWarehouse"
                 round
                 position="bottom">
        <custom-popup title="仓库"
                      value-key="name"
                      :columns="warehouseColumns"
                      @cancel="showWarehouse = false"
                      @confirm="warehouseConfirm"></custom-popup>
      </van-popup>
      <van-popup v-model="showWarehousingType"
                 round
                 position="bottom">
        <custom-popup title="入库类型"
                      value-key="value"
                      :columns="warehousingTypeColumns"
                      @cancel="showWarehousingType = false"
                      @confirm="warehousingTypeConfirm"></custom-popup>
      </van-popup>
      <van-popup v-model="showStatus"
                 round
                 position="bottom">
        <custom-popup title="状态"
                      value-key="label"
                      :columns="statusColumns"
                      @cancel="showStatus = false"
                      @confirm="statusConfirm"></custom-popup>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  ref="list"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemClick(item.id)">
            <item-card :title="item.deliveryNumber">
              <div class="content">
                <div class="content-row">
                  <div class="content-item"
                       style="width:100%;">
                    <span class="name">提货日期:</span>
                    <span>{{ item.pickTime }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">请领单位:</span>
                    <span>{{ item.organizationName }}</span>
                  </div>
                  <div class="content-item">
                    <span class="name">出库类型:</span>
                    <span>{{ item.deliveryType }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">申请人:</span>
                    <span>{{ item.applicantName }}</span>
                  </div>
                  <div class="content-item">
                    <span class="name">提货人:</span>
                    <span>{{ item.pickName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">联系电话:</span>
                    <span>{{ item.pickMobileNumber }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">申请时间:</span>
                    <span>{{ item.creationTime }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">状态:</span>
                    <span>{{ item.status | parseStatus }}</span>
                  </div>
                </div>
              </div>
              <template #footer>
                <div class="action">
                  <van-button class="detail-btn"
                              type="info"
                              size="small"
                              round>查看</van-button>
                </div>
              </template>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <div class="footer">
      <van-button class="add-btn"
                  type="info"
                  round
                  @click="addOutOfStock">新增出库</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityOutOfStockList.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { find } from "lodash-es";
import Filter from "ldap-filters";
export default {
  name: "CommodityOutOfStockList",
  data() {
    return {
      searchType: "deliveryNumber",
      searchTypeOptions: [{ text: "出库单号", value: "deliveryNumber" }],
      searchContent: "",

      filter: {
        startDate: "",
        endDate: "",
        warehouse: "",
        warehousingType: "",
        status: ""
      },
      minDate: this.$moment().subtract(0.5, "y"),

      showDate: false,
      showStartDate: false,
      showEndDate: false,
      showWarehouse: false,
      warehouseColumns: [],
      showWarehousingType: false,
      warehousingTypeColumns: [],
      showStatus: false,
      statusColumns: [
        { label: "全部状态", value: "all" },
        { label: "已提交", value: "SUBMITTED" },
        { label: "暂存", value: "STAGING" }
      ],

      list: [],
      listStatus: {
        loading: true,
        finished: false
      },
      page: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {
    startDateComputed() {
      if (!this.filter.startDate) return "起始时间";
      return this.filter.startDate;
    },
    endDateComputed() {
      if (!this.filter.endDate) return "结束时间";
      return this.filter.endDate;
    },
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "仓库名称";
      let item = find(this.warehouseColumns, item => item.id == this.filter.warehouse);
      return item ? item.name : "仓库名称";
    },
    warehousingTypeComputed() {
      if (!this.warehousingTypeColumns.length) return "入库类型";
      return this.filter.warehousingType ? this.filter.warehousingType : "入库类型";
    },
    statusComputed() {
      let item = find(this.statusColumns, item => item.value == this.filter.status);
      return item ? item.label : "状态";
    }
  },
  methods: {
    async getCommodityDeliveryList(sign = false) {
      let params = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize
      };
      /* if (this.order.direction !== null) {
        let sort = [{ fieldName: this.order.fieldName, direction: this.order.direction }];
        params.sort = encodeURI(JSON.stringify(sort));
      } */
      let sort = [{ fieldName: "creationTime", direction: "DESC" }];
      params.sort = encodeURI(JSON.stringify(sort));
      params.query = this.getQuery();
      // let ret = await this.$api.getStockWarehousingList({ params });
      let ret = await this.$api.getStockDeliveryList({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery() {
      let filters = [];
      if (this.filter.startDate) {
        let query = Filter.attribute("creationTime").gte(`${this.filter.startDate} 00:00:00`);
        filters.push(query);
      }
      if (this.filter.endDate) {
        let query = Filter.attribute("lastUpdateTime").lte(`${this.filter.endDate} 23:59:59`);
        filters.push(query);
      }

      if (this.filter.status && this.filter.status != "all") {
        let query = Filter.attribute("status").equalTo(this.filter.status);
        filters.push(query);
      }
      if (this.searchContent != "") {
        let query = Filter.attribute(this.searchType).contains(this.searchContent);
        filters.push(query);
      }

      if (this.filter.warehouse && this.filter.warehouse != "all") {
        let query = Filter.attribute("warehouseId").equalTo(this.filter.warehouse);
        filters.push(query);
        if (filters.length == 1) {
          return filters[0].toString();
        } else if (filters.length > 1) {
          return Filter.AND(filters).toString();
        }
      } else {
        let outFilters = [];
        if (filters.length) {
          let query = Filter.AND(filters);
          outFilters.push(query);
        }

        let query = Filter.OR([Filter.attribute("WAREHOUSEIDS").equalTo("")]);
        outFilters.push(query);

        if (outFilters.length == 1) {
          return outFilters[0].toString();
        } else if (outFilters.length > 1) {
          return Filter.AND(outFilters).toString();
        }
      }
    },
    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_KEEPER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = [{ id: "all", name: "全部仓库" }, ...ret.data];
    },
    async getWarehousingType() {
      let query = "name[sin]入库类型";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.warehousingTypeColumns = [{ id: "all", value: "全部类型" }, ...ret.data["入库类型"][0].dictOptions];
    },
    startDateFormatter(day) {
      if (this.endDateComputed == "结束时间") return day;
      if (day.date.getTime() - this.$moment(this.endDateComputed).add(1, "d") >= 0) {
        day.type = "disabled";
      }
      return day;
    },
    endDateFormatter(day) {
      if (this.startDateComputed == "结束时间") return day;
      if (day.date.getTime() - this.$moment(this.startDateComputed).subtract(1, "d") <= 0) {
        day.type = "disabled";
      }
      return day;
    },
    onSearch() {
      this.getInitialData();
    },
    startDateClick() {
      this.showStartDate = true;
    },
    endDateClick() {
      this.showEndDate = true;
    },
    startDateConfirm(arg) {
      this.filter.startDate = this.$moment(arg).format("YYYY-MM-DD");
      this.showStartDate = false;
      this.getInitialData();
    },
    endDateConfirm(arg) {
      this.filter.endDate = this.$moment(arg).format("YYYY-MM-DD");
      this.showEndDate = false;
      this.getInitialData();
    },
    warehouseClick() {
      // let offset = this.$refs.list.check();
      // console.log(offset);
      this.showWarehouse = true;
    },
    warehousingTypeClick() {
      this.showWarehousingType = true;
    },
    statusClick() {
      this.showStatus = true;
    },
    warehouseConfirm(arg) {
      this.filter.warehouse = arg.id;
      this.showWarehouse = false;
      this.getInitialData();
    },
    warehousingTypeConfirm(arg) {
      this.filter.warehousingType = arg.value;
      this.showWarehousingType = false;
      this.getInitialData();
    },
    statusConfirm(arg) {
      this.filter.status = arg.value;
      this.showStatus = false;
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getCommodityDeliveryList();
    },
    addOutOfStock() {
      this.$router.push({
        name: "addCommodityOutOfStock"
      });
    },
    itemClick(id) {
      this.$router.push({
        name: "commodityOutOfStockDetail",
        params: {
          id
        }
      });
    },
    init() {
      this.getInitialData();
    },
    onLoad() {
      this.getCommodityDeliveryList(true);
    }
  },
  filters: {
    parseStatus(status) {
      if (status == "SUBMITTED") {
        return "已提交";
      }
      if (status == "STAGING") {
        return "暂存";
      }
      return "";
    }
  },
  created() {
    this.getWarehouseList();
    this.getWarehousingType();
    this.getCommodityDeliveryList();
  }
};
</script>

<style></style>
